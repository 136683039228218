// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-referenzen-js": () => import("./../../../src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-templates-zutrittskontrolle-default-js": () => import("./../../../src/templates/zutrittskontrolle-default.js" /* webpackChunkName: "component---src-templates-zutrittskontrolle-default-js" */)
}

